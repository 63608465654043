@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

:root{
  --flo-primary-dark-color: #01CCA7;
  --flo-coal-dark-color: #4D4D4D;   /* for texts */
  --flo-coal-light-color: #6E6E6E;  /* for texts */
  --flo-carbon-color: #1A1A1A;
  --flo-gray-dark-color: #949494;
  --flo-gray-light-color: #F0F0F0;  /* for borders */
  --flo-gray-lighter-color: #F5F5F5;/* for background */
  --flo-yellow-dark-color: #9C7309;
  --flo-gray-lightest-color: #FAFAFA; 
}

/* sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'K */
code {
  font-family: 'Inter';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

@layer utilities {
  .settings_list-status_yellow{
    background: linear-gradient(0deg, rgba(239, 208, 128, 0.1), rgba(239, 208, 128, 0.1)), #FFFFFF;
  }
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  font-family: 'IBM Plex Mono', monospace;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #1A1A1A;
  font-family: 'IBM Plex Mono', monospace;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600');
}

.sketch-picker .flexbox-fix div{
  min-height: 24px;
  min-width: 24px;
}

.sketch-picker .flexbox-fix .hue-horizontal div{
  min-width: 4px;
}